// sept 2024
//  se eliminan las viejas referencias aionic y cordova


import { VariationsPageModule } from './pages/variations/variations.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { environment } from 'src/environments/environment';
// import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ChooseAddressPageModule } from 'src/app/pages/choose-address/choose-address.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { Camera } from '@ionic-native/camera/ngx';
// import { PayPal } from '@ionic-native/paypal/ngx';

import { SelectDriversPageModule } from './pages/select-drivers/select-drivers.module';
// import { VariationPageModule } from './pages/variation/variation.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
// import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReactiveFormsModule } from '@angular/forms';
import { AvisoComponent } from './pages/aviso/aviso.component';
import { TaquizaComponent } from './shared/taquiza/taquiza.component';
import { HelpVideoComponent } from './pages/help-video/help-video.component';

export function customTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
    declarations: [AppComponent, AvisoComponent, TaquizaComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        ChooseAddressPageModule,
        SelectDriversPageModule,
        // VariationPageModule,
        VariationsPageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: customTranslateLoader,
                deps: [HttpClient]
            }, useDefaultLang: true,
        }),
       SharedModule,
        ServiceWorkerModule.register('./combined-service-worker.js', { enabled: true, registrationStrategy: "registerImmediately",}),
        
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Diagnostic,
        Geolocation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }