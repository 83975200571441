<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button mode="md"></ion-back-button>
    </ion-buttons>
    <!-- <ion-title *ngIf="from ==='cart'">{{'Delivery Address' | translate}}</ion-title> -->
    <ion-title *ngIf="from ==='accont'">Agrega o escoge una dirección</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="group  bg-light text-dark ">
    <div class="mainContent">
      <ion-button (click)="addNew()" expand="block" fill="solid" shape="round" color="primary">
        <ion-icon slot="start" name="add"></ion-icon>
        Agrega dirección
      </ion-button>
      <div class="noData" *ngIf="!dummy?.length && !myaddress?.length"
        [style.backgroundImage]="'url(assets/nothing.jpg)'">
      </div>
      <div *ngFor="let item of dummy" >
        <ion-list lines="none">
          <ion-item>
            <ion-thumbnail slot="start">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
              <h3>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
      <ion-list *ngIf="from ==='cart'" class="bg-light text-dark ">
        <ion-radio-group [(ngModel)]="selectedAddress">
          <ion-item *ngFor="let item of myaddress" class="list bg-primary text-white">
            <ion-label class="title">{{item.title}} <br>
              <span class="name">{{item.house}} {{item.landmark}} {{item.address}}</span></ion-label>
            <!-- <ion-label class="name"></ion-label> -->
            <ion-radio [value]="item.id" slot="start" mode="md"></ion-radio>
          </ion-item>

          <!-- <ion-item  class="list">
            <ion-label class="title">Recoger en sucursal <br>
              <span class="name">Sur 109-A, No. 402, col. Héroes de Churubusco, del. Iztapalapa.</span></ion-label>
            <ion-radio [value]="'branch'" slot="start" mode="md"></ion-radio>
          </ion-item> -->

        </ion-radio-group>
      </ion-list>
      <div *ngIf="from ==='accont'">
        <div class="list" *ngFor="let item of myaddress" scrollX="true">
          <div>
            <ion-label class="title">{{item.title}}</ion-label>
            <ion-label class="name">{{item.house}} {{item.landmark}} {{item.address}}
            </ion-label>
          </div>
          <div class="moreIcon">
            <div (click)="edit(item)">
              <ion-icon name="create-outline"></ion-icon>
            </div>
            <div (click)="remove(item)">
              <ion-icon name="trash-outline"></ion-icon>
            </div>
          </div>
          <!-- <ion-icon class="moreIcon" (click)="openMenu(item,$event)" name="ellipsis-vertical-outline"> -->
          <!-- </ion-icon> -->
        </div>
      </div>
    </div>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="selectedAddress" (click)="selectAddress()">
    <ion-fab-button>
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>